.backButton{
    height: 20%;
    align-self: self-start;
    border: none;
}

.popover {
    /* background:transparent; */
    /* background-size: cover; */
    position: absolute;
    height: 100vh; /* Ajusta la altura según tus necesidades */
    width: 100%; /* O ajusta el ancho según tus necesidades */
    z-index: 2000;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popover::before {
    content: "";
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px); /* Ajusta el valor de difuminado según tus necesidades */
}

.popover .content{
    width: 80%;
    height: fit-content;
    background-color: white;
    border: var(--color-purple-dark) solid 2px;
    border-radius: 40px;
    backdrop-filter: blur(0px); /* Ajusta el valor de difuminado según tus necesidades */
    text-align: center;
    color: grey;
    padding: 5%;
}


.popover .content .top{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.popover .content .top :first-child{
    color: var(--color-purple-dark);
    font-size: 3.5svh;
    margin: 1%;
}

.popover .content .bot{
    padding: 5%;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 5%;
    flex: 1;
    height: 10svh;
}

.botonRealizarActividadOkRosado{
    border-radius: 20px;
    width: 30%;
    color: var(--color-purple-dark);
    border: 2px solid var(--color-purple-dark);
    background-color: white;
}

.botonRealizarActividadOk{
    border-radius: 20px;
    width: 30%;
    color: white;
    background-color: var(--color-purple-dark);
}

.toolbar_Vista_video{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    background-image: 
    linear-gradient(rgba(69,34,95, 0.85), rgba(215,66,134, 0.5)), 
    url(../../../../public/assets/images/img_cerebro_video.png); 
    background-size: 100% 51%; 
    background-position: top; 
    background-repeat: no-repeat;
    border-radius: 0 0 25px 25px;
}

.ButonFinalizar{
    padding: 2% 10%;
    border-radius: 1em;
    background: var(--color-purple-dark);
    color: white;
    font-size: 1em;
    font-weight: 700;
    z-index: 1;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

.descripcion{
    font-size: 1.5em;
    font-weight: 500;
    color: black;
    text-align: center;
    padding: 1em 5%;
}